/**
* Main CSS Stylesheet
* TV and Film Supplies 1.0
* Pace Commerce
*
*/

/*---------- Variables ---------*/

$dark_text: #212121;
$pink_color: #cd1141;

$weight_light: 300;
$weight_regular: 400;
$weight_medium: 500;
$weight_bold: 700;

/*-------- END VARIABLES -------*/

.no-gutter {
    margin-left: 0;
    margin-right: 0;

    > [class*='col-'] {
        padding-right: 0;
        padding-left: 0;
    }
}


/*----------- FONTS -----------*/

@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700");

body {
    background: #fff;
    font-size: 18px;
    font-family: 'Roboto', 'Open Sans', sans-serif;
    font-weight: $weight_light;
    color: #616161;
    letter-spacing: 0.25px;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
}

a {
    img {
        border: 0;
    }

    border: 0;

    &:hover {
        text-decoration: none;
    }
}

ul, ol {
    margin-left: 30px;

    @media(min-width: 768px) {
        margin-left: 60px;
    }
}

#bannerSlider img {
    width: 100%;
}

.clearBoth, .clearfix {
    clear: both;
}

a {
    color: #616161;

    &:hover, &:focus {
        color: $pink_color;
    }
}

/*----------- TYPOGRAPHY ----------*/

h1, h2, h3 {
	margin-bottom: 30px;
}

h1, .h1 {
    font-size: 39px;
    font-weight: 700;

    @media(min-width: 768px) {
    	font-size: 54px;
    }
}

h2, .h2 {
    font-size: 35px;
    font-weight: 700;
    color: $dark_text;

    @media(min-width: 768px) {
    	font-size: 50px;
    }
}

h3, .h3 {
    font-size: 30px;
    font-weight: 700;
	
	@media(min-width: 768px) {
		font-size: 40px;
	}

}

h4, .h4 {
    font-size: 25px;
    font-weight: 700;

    @media(min-width: 768px) {
    	font-size: 35px;
    }
}

h5, .h5 {
    font-size: 20px;
    color: $dark_text;
    font-weight: 700;

    @media(min-width: 768px) {
    	font-size: 30px;
    }
}

h6, .h6 {
    font-size: 20px;
    font-weight: 700;
}

p {
    margin: 0 0 20px;
}

fieldset {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;

    legend {
    	font-weight: 400;
    	padding: 0 10px;
    }

    fieldset {
    	border: none;

    	legend {
    		padding-left: 0;
    	}
    }
}

address {
	font-weight: 400;
}

.alert {
	color: $pink_color;
	font-weight: bold;
}

.uppercase {
    text-transform: uppercase;
}

.bold {
	font-weight: bold;
	color: #212121;
}

.center {
    display: block;
    text-align: center;
}

.img-center {
    margin: 0 auto;
    display: block;
}

.spacer-sm {
    padding: 20px 0;
}

.spacer-md {
    padding: 40px 0;
}

.spacer-lg {
    padding: 70px 0;
}

.table-striped {
    background: #fff;
    border: 1px solid #dadada;
}


/*---------------- BUTTONS ----------------*/

.btn {
	border-radius: 0;
	padding: 15px 40px;
	text-transform: uppercase;
	font-weight: $weight_light;
    font-size: 16px;
    text-shadow: none;
}

.btn-success {
	border-color: #5cb85c;

	&:hover {
		border-color: #47a447;
	}

	@media(min-width: 992px) {
    	min-width: 230px;
    }
}

.btn-primary {
	background: #212121;
	border-color: #212121;

	&:hover {
		background: #333333;
		border-color: #333333;
	}
}

.btn-white {
	background: #fff;
	color: $dark_text;

	@media(min-width: 992px) {
    	min-width: 230px;
    }
}

.btn-default {
    background: $pink_color;
    color: #fff;

    /* height: 55px; */
    font-weight: $weight_light;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 0;
    border: none;
    padding: 15px 40px;
    text-shadow: none;

    @media(min-width: 992px) {
    	min-width: 230px;
    }

    &:hover {
        background: #95092d;
        color: #fff;
    }
}

/*---------------- END BUTTONS ----------------*/

/*---------------- BREADCRUMB ------------------*/

.breadcrumb {
    background: #fff;
    margin-left: 0;
    float: left;
    padding: 8px 15px 8px 0;
    width: 100%;

    .divider {
    	margin: 0 10px;
    }

    a, span {
    	float: left;
    }
}

/*---------------- END BREADCRUMB --------------*/


/*---------------- PAGINATION --------------*/
.pagination {

    .active > a {
		background-color: $pink_color;
		border-color: $pink_color;

		&:hover {
			background-color: $pink_color;
			border-color: $pink_color;
		}
	}

	li:first-child > a , li:last-child > a {
		border-radius: 0;
	}
}
/*---------------- END PAGINATION --------------*/


.basketSelector {
    width: 162px;
    height: 16px;
    margin: 0;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    background-color: #fff;
    color: #000;
    text-align: center;
}

.centered_shopping_sidebox {
    text-align: center;
}

/*---------------- SIDEBOXES --------------*/



/*---------------- END SIDEBOXES --------------*/


.sideboxes {
	.leftBoxContainer {
		padding: 10px 0;
		border-top: 2px solid #EBEBEB;
	}

	.leftBoxHeading {
		font-size: 14px;
	}
}


// Smart sideboxes
#smartcatlisting, #manufacturers {

	#smartcatlistingHeading, #manufacturersHeading {
		&:after {
        	content: "^";
        	margin-left: 10px;
    	}
	}

	#smartcatlistingContent, #manufacturersContent {
		ul {
			padding-left: 10px;
			li {
				display: inline-block;
				width: 100%;
				font-size: 14px;
				margin-bottom: 10px;

				div {
					display: inline;
				}
			}

			.smart_cat_matches, .filter_manu_matches {
				float: right;
				background: #ddd;
				padding: 0 10px;
    			border-radius: 10px;
    			//color: white;
			}
		}
	}
}


/*---------------- NAVIGATION --------------*/


#navEZPagesTop {
    background-color: #ebebeb;
    border-color: transparent;
    background-image: none;
    box-shadow: none;
    border: 0;
    padding: 0;
    border-top: 1px solid #ddd;
    border-radius: 0;
    clear: both;

    @media (min-width: 992px) {
        padding: 8px 0;
    }

    .dropdown-submenu {
    	clear: both;
		
		@media(min-width: 992px) {
			clear: none;
		}

    }
}

.navbar-inverse {
    .navbar-nav > li > a {
        color: $dark_text;
        text-transform: uppercase;
        font-family: 'Roboto';
        font-size: 15px;
        font-weight: bold;
    }

    .navbar-collapse, .navbar-form {
        border-color: #ddd;
    }

    .navbar-nav > li > a {
        &:hover, &:focus {
            color: $pink_color;
        }
    }

    .navbar-brand {
        color: $dark_text;

        &:focus, &:hover {
            color: $dark_text;
        }
    }

    .navbar-toggle {
        background: #212121;
        border-radius: 0;
    }
}

.nav > li > a {
    padding: 10px;
}

.navSplitPagesResult {
    padding: 13px 0px;
    display: block;
}

.navNextPrevCounter {
    font-size: 0.9em;
}

.navNextPrevList {
    display: inline;
    padding: 0.5em 0em;
    list-style: none;
}

#navMain a {
    color: #000;
    font-size: 10px;
    text-align: right;
    text-decoration: none;
}

#basketArea {
    float: right;
    width: 230px;
    font-size: 8pt;
    font-weight: bold;
    padding: 15px 0px 12px 0px;
    color: #20282c;
}

#shoppingCartHeader {
    position: relative;
    padding: 5px 0px 5px 0px;
}

#shoppingCartContent {
    position: relative;
    padding: 5px 0px 0px 0px;

    a {
        color: #000;
    }
}

#navMainSearch {
    float: left;
    padding: 10px 0px 10px 0px;
    font-size: 8pt;
    color: #20282c;
    font-weight: bold;
}

#navEZPagesTOCWrapper {
    font-weight: bold;
    float: right;
    height: 1%;
    border: 1px solid #9a9a9a;
}

#navEZPagesTOC ul {
    padding: 0.5em 0em;
    list-style: none;
    line-height: 1.5em;

    li a {
        padding: 0em 0.5em;
    }
}

#navCategoryIcon {
    margin: 0.5em;
}

.buttonRow {
    padding-top: 5px;
    padding-bottom: 5px;
}

#mainBannerImage {
    background: transparent url(../images/banner.jpg) no-repeat 0px 0px;
    border: 1px solid #b7babb;
    margin: 0px 0px 4px 0px;
    height: 185px;
    width: 408px;
    display: none;
}

#bannerText {
    position: absolute;
    font-size: 14pt;
    font-weight: normal;
    color: #323b42;
    text-transform: uppercase;
    top: 10px;
    left: 360px;
}

#productDescription, .shippingEstimatorWrapper {
    padding: 1.0em;
    padding-left: 0px;
}

.important {
    font-weight: bold;
}

.small {
    font-size: 12px;
}

.smallText, #siteinfoLegal, #siteinfoCredits, #siteinfoStatus, #siteinfoIP {
    font-size: 0.9em;
}

.radioButtonLabel {
    margin-right: 5px;
}

/*Shopping Cart Display*/

.tableHeading {
    background-color: #e9e9e9;
}

#shoppingCartDefault {

	#scTotalHeading {
		text-align: right;
	}

	#cartSubTotal {
	    background-color: #e9e9e9;
	    font-weight: bold;
	    text-align: right;
	    line-height: 2.2em;
	    padding: 5px 10px;
	}

	.cartSubText {
		float: left;
	}

	#cartContentsDisplay {
	    border-bottom: 1px solid #a4b1a7;
	    font-weight: $weight_bold;

	    tbody {
	    	color: $dark_text;
	    }
	}


	.cartSubTotal, #orderTotals {
		border-bottom: 1px solid #a4b1a7;
		padding-bottom: 15px;
		margin-bottom: 20px;
	}

    .cartRemoveItemDisplay a {
        background: url(../images/icon-cart-remove.png) no-repeat top center;
        display: block;
        width: 32px;
        height: 32px;
    }
	
	.button_checkout, .button_continue_shopping {
		width: 100%;
			
		/*@media(min-width: 992px) {
			width: auto;
		}*/
	}

}

#cartSubTotal .cartSubText, #cartSubTotal .cartSubPrice, #orderTotals .totalBox, #orderTotals .lineTitle {
    display: inline-block;
}

.cartProductDisplay, .cartUnitDisplay, .cartTotalDisplay, .cartRemoveItemDisplay, .cartQuantityUpdate, .cartQuantity, #cartInstructionsDisplay, .cartTotalsDisplay {
    padding: 0.5em 0em;
}

.cartTotalDisplay {
    text-align: right;
    padding-right: 0.2em;
}

#cartProdTitle {
	color: $dark_text;
	font-weight: $weight_bold;
}

.cartQuantity {
    width: 4.7em;
    padding: 4px;
    text-align: right;
}

.cartNewItem {
    color: #000;
    font-size: 8pt;
}

#shoppingcartContent ul li a {
    text-decoration: none;
    color: #000;
}

.cartOldItem {
    color: #000;
    font-size: 8pt;
}

.cartBoxTotal {
    text-align: right;
    font-weight: bold;
}

.cartRemoveItemDisplay {
    width: 3.5em;
}

.cartAttribsList {
    margin-left: 0;

    ul {
        padding: 0;
    }

    li {
        color: #666;
        text-transform: uppercase;
        font-size: 12px;
        list-style: none;
    }
}

#orderAttribsList li {
    color: #666;
    text-transform: uppercase;
    font-size: 12px;
    list-style: none;
}

#cartImage img {
    margin: 0 5px 5px 0;
}

.ccinfo img {
    height: 19px;
    width: auto;
}

.col-l {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 10px;
}

.col-r {
    padding-right: 0;
    padding-left: 0;
}

#mediaManager {
    width: 50%;
    padding: 0.5em;
    background-color: #E4FEf5;
    border: 1px solid #003D00;
}

.orderHistList {
    margin: 1em;
    padding: 0.2em 0em;
    list-style: none;
}

#cartBoxListWrapper ul, #ezPageBoxList ul {
    list-style: none;
}

#cartBoxListWrapper li, #ezPageBoxList li, .cartBoxTotal {
    padding: 0.2em 0em;
}

#cartBoxListWrapper {
    width: 170px;
    padding-top: 6px;

    th {
        font-weight: normal;
        font-size: 11px;
        color: #fff;
        text-align: center;
        padding: 3px 2px 4px 0;
    }

    td {
        padding: 3px 2px 4px 0px;
        font-size: 11px;
    }
}

#cartBoxEmpty, #cartBoxVoucherBalance {
    font-weight: bold;
}

#cartBoxEmpty {
    color: #000;
    padding-top: 10px;
    padding-left: 23px;
}

.totalBox {
    width: 5.5em;
    text-align: right;
    padding: 0.2em;
}

.lineTitle {
    text-align: right;
    padding: 0.2em;
}

.amount {
    text-align: right;
    padding: 0.2em;
    width: 5.5em;
}

.attribImg {
    width: 20%;
    margin: 0.3em 0em;
}

#productReviewsDefaultProductImage img {
    border: 1px solid #ccc;
}

#alsoPurchased {
    background: transparent;
    width: 100%;
}

.centerBoxContentsAlsoPurch .productBoxContentsWrapper {
    padding: 10px;
    border: #ebebeb solid 1px;
    float: left;
}

.productImgWrapper {
    height: 250px;
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    display: block;
    float: left;
    line-height: 200px;
    text-align: center;
    overflow: hidden;

    img {
        vertical-align: middle;
        max-width: 100%;
        height: auto;
    }
}

#newsletterHeader {
    position: absolute;
    left: 0px;
    bottom: 70px;
    color: #fff;

    input {
        color: #fff;
    }

    label input {
        color: #000;
    }
}

#cartPayments {
    padding: 5px;
}

#top {
    color: #fff;
    font-size: 15px;
    text-align: center;
}

#bottom {
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding-top: 55px;
}

#navEZPagesNumber {
    width: 229px;
    height: 35px;
    top: 2px;
    background: url(../images/phonenumber.png) no-repeat;
    position: absolute;
    right: 0px;
}

#seanKellyHeader {
    font-size: 17px;
    font-style: italic;
}

.newsLetterHeader {
    padding: 5px 0px;
}

#bottom a, #top a {
    color: #fff;
    text-decoration: none;
}

#bottom a:hover, #top a:hover {
    text-decoration: none;
}

#productGeneral form fieldset, #Name, #Email, #Htel {
    width: 200px;
}

#newsletterHeader a {
    color: #fff;
}

#bestsellers {
    .wrapper {
        margin: 0em 0em 0em 1em;
    }

    ol {
        margin-left: 1.1em;
    }

    li {
        margin: 0.3em 0em 0.3em 0em;
    }
}

#bannerboxHeading {
    background-color: #0000CC;
}

#upProductsHeading {
    text-align: left;
}

#upDateHeading {
    text-align: right;
}

/* Fix for category list spacing issue in IE6 */

#categoriesContent ul li a, #ezpagesContent ul li a {
    display: inline-block;
}

#categoriesContent ul li a, #ezpagesContent ul li a {
    display: block;
}

#Content #searchButton input {
    margin-top: -2px;
}

#paymentsContent {
    text-align: center;
}

/* end categories box links */

/*misc*/

a, input {
    outline: none;

    &:focus {
        outline: none;
    }
}

.btn {
    &:focus, &:active:focus, &.active:focus, &.focus, &:active.focus, &.active.focus {
        outline: none;
    }
}

textarea {
    height: auto !important;
}

.form-control, input[type="text"], select, textarea {
    border-radius: 0;
    box-shadow: none;
    border: 1px solid #cccccc;
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px;
    font-size: 18px;
    line-height: 1.428571429;
    color: #555555;
    vertical-align: middle;
    background-color: #ffffff;
    -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

input {
    &[type="password"] {
        border-radius: 0;
        box-shadow: none;
        border: 1px solid #cccccc;
        display: block;
        width: 100%;
        height: 34px;
        padding: 6px;
        font-size: 14px;
        line-height: 1.428571429;
        color: #555555;
        vertical-align: middle;
        background-color: #ffffff;
        -webkit-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }

    &[type="text"]:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    }
}

select:focus, textarea:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

input {
    &[type="radio"], &[type="checkbox"] {
        margin: 4px 2px 0;
    }
}

#shoppingcartContent {
    border: none;
}

.centerBoxContentsFeatured img, .centerBoxContentsSpecials img, .centerBoxContentsNew img {
    margin-right: 10px;
}

.centerBoxContentsFeatured a, .centerBoxContentsSpecials a, .centerBoxContentsNew a {
    font-weight: bold;
}

.indexImageLink {
    padding: 3px 0px 0px 0px;
    display: block;
}

.forward, #shoppingCartDefault #PPECbutton {
    float: right;
}

.hiddenField {
    display: none;
}

.visibleField {
    display: inline;
}

.accountTotalDisplay, .accountTaxDisplay {
    width: 20%;
    text-align: right;
}

.accountQuantityDisplay {
    width: 10%;
    vertical-align: top;
}

#productsListingTopNumber {
    padding-bottom: 4px;
}

.productListing-rowheading {
    background-color: #116eb8;
    height: 2em;
    color: #fff;
}

th.productListing-heading {
    padding: 4px;

    a {
        color: #fff;
        text-decoration: none;
    }
}

.productListing-data {
    vertical-align: top;
    padding: 10px 0px;
    border-bottom: 1px dotted #ccc;
}

#listCell0-0 {
    width: 100px;
}

#siteMapList {
    width: 90%;
    float: right;
}

.ratingRow {
    margin: 1em 0em 1.5em 0em;
}

LABEL#textAreaReviews {
    font-weight: normal;
    margin: 1em 0em;
}

#popupShippingEstimator, #popupSearchHelp, #popupAdditionalImage, #popupImage, #popupCVVHelp, #popupCouponHelp, #popupAtrribsQuantityPricesHelp {
    background-color: #ffffff;
}

#infoShoppingCart {
    background-color: #ffffff;
    padding: 4px;
}

#editablesideboxHeading {
    display: none;
}

#productListing {
    padding-bottom: 10px;
    margin-top: 30px;
}

#whatsnewContent {
    background: transparent;
}

#shopCartButtons {
    position: relative;
    display: block;
    width: 100%;
}

.productNameLink {
    text-align: left;
    min-height: 20px;
    background: #434242;
    padding-left: 5px;
}

.homeTitle a {
    color: #fff;
    text-align: left;
    font-weight: bold;
    text-decoration: none;
    font-size: 9px;
}

#searchByCategory {
    margin-left: 2px;

    select, input {
        border: 1px solid #000;
        background: #fff;
    }
}

.testimonial {
    color: #000;
    padding: 0 5px 5px 5px;
    text-align: left;

    p {
        padding: 5px 0;
    }

    span {
        float: right;
    }
}

.testimonialImage {
    text-align: center;
}

#editablesideboxContent img {
    margin-top: 10px;
}

.indexDescription {
    font-size: 11px;
}

#featuredPrice {
    margin: 0px 0px 0px 3px;
    text-align: left;
}

.indexImageBlock {
    float: left;
    width: 50px;
}

.indexContentBlock {
    float: right;
    width: 130px;
}

#ourPrice {
    color: #000;
}

#GeoTrustContent {
    text-align: center;
    padding-top: 10px;
}

#shoppingcartHeading a {
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
}

#searchInput input {
    width: 300px;
}

#subcategories-header {
    display: none !important;
}

#Content {
    border: none;
}

#tab3 .centerBoxContentsAlsoPurch {
    padding: 0px !important;
    margin-right: 4px;
    margin-left: 4px;
    width: 31% !important;
    text-align: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    height: 150px;
}

#testimonials-manager b {
    display: block;
    padding-left: 4px;
}

/****************************** NEW DESIGN STARTS HERE *****************************/

/*---------------- HEADER ----------------*/

#headerTop {
    background: #d55089;
}

#headerTopNavLeft {
    float: left;
    width: 6px;
    height: 27px;
    background: url(../images/new/headertopnavcorners.png) top left no-repeat;
}

#headerTopNavRight {
    float: left;
    width: 6px;
    height: 27px;
    background: url(../images/new/headertopnavcorners.png) top right no-repeat;
}

.headerTopNav {
    ul {
        float: left;
        list-style: none;
        padding: 5px 0;
    }

    li {
        float: left;
        margin: 0 8px;
        padding: 5px 0;
    }

    a {
        color: #fff;
        text-decoration: none;
        font-size: 11px;
    }
}

.headerCenter {
    background: #ebebeb;
    position: relative;



    .phone {
        background: url(../images/icon-phone.png) no-repeat left;
        font-size: 14px;
        padding: 18px 0 18px 30px;

        .number {
            font-weight: bold;
        }
    }
	
	.logo {

		padding: 28px 28px 28px 0;

		@media(min-width: 768px) {
			padding: 10px 25px 10px 0;
		}

		@media(min-width: 1200px) {
			padding: 28px 28px 28px 0;
		}

		&:after {
			background: $pink_color;
		    content: "";
		    position: absolute;
		    top: 0;
		    width: 235%;
		    height: 100%;
		    z-index: 0;
		    left: -135%;

			@media(min-width: 1200px) {
	    		background: url(../images/logo-bg.png) no-repeat 188px $pink_color;
			}

    	}

	    .mainLogo {
	    	width: 100%;
	    	height: auto;
	    	position: relative;
	    	z-index: 1;
	    }

	    a {
	    	span {
	    		color: #fff;
	    		display: block;
	    		position: relative;
	    		font-style: italic;
	    		z-index: 2;
	    		text-align: right;
	    	}
	    }
    }
}

.headerBottomWrap {
	clear: both;

	@media(min-width: 1200px) {
		clear: none;
	}
}

#logo {
    background: url(../images/logo.jpg) center no-repeat;
    display: block;
    height: 100px;
}

#headerDelivery h3 {
    color: #d55089;
    margin: 0;
}

#headerContact {
    h4 {
        color: #d55089;
        font-size: 14px;
        margin-top: 10px;
    }

    p {
        float: left;
        margin-bottom: 3px;
    }

    h2 {
        margin-top: 25px;
        font-size: 1.6em;
        color: #E22086;
        background: url(../images/phone.png) center left no-repeat;
        padding: 4px 0 0 17px;
        width: 100%;
        clear: both;
    }

    h4 {
        float: left;
    }
}

#languageSelect {
    p {
        float: left;
        color: #fff;
        margin-right: 5px;
        font-size: 11px;
    }

    a {
        float: left;
        margin-left: 5px;
    }
}

#headerBasket {
    margin: 15px 0px 5px 0;

    /*float: left;*/
    form {
        width: 100%;
        margin-bottom: 8px;

        select {
            padding: 6px 3px;
            font-size: 12px;
        }
    }
}

.headerTop {
    padding: 20px 0;

    @media(min-width: 768px) and (max-width: 1200px) {
    	padding: 23px 0;
    }

    .row {
    	@media(min-width: 768px) {
    		margin-left: 0;
    		margin-right: 0;

    		.col-xs-6 {
    			padding: 0;
    			width: auto;
    		}
    	}
    }

    .header-buttons {
	   	border: 1px solid #ccc;
	    width: 100%;
	    display: inline-block;
	    padding: 10px;
	    text-align: center;
	    background: #fff;

	    @media(min-width: 768px) {
	    	border: none;
	    	width: auto;
	    	display: block;
	    	background: inherit;
	    	padding: 0;
	    }
    }

    #basketPrice {
        font-size: 14px;
        background: url(../images/basketicon.png) 0 0 no-repeat;
        padding: 0 0 5px 28px;
        position: relative;
        display: inline-block;

        @media(min-width: 768px) {
        	float: left;
        }
    }
.header-buttons {
    margin-left: 0;
    

    li {
        display: inline;
    }

    .headerBasket{
        margin: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 10px;

        @media(min-width: 768px){
            padding-bottom: 0;
            border-bottom: none;
            margin: 0;
        }
    }

    .headerLogin {
        margin: 10px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 18px;

        @media(min-width: 768px){
            border-bottom: none;
            padding-bottom: 0;
        }

        .basketLogin {
            font-size: 14px;
            background: url(../images/login.png) 0 0 no-repeat;
            padding: 0 0 5px 28px;
            // display: inline-block;
            position: relative;

            @media(min-width: 768px) {
                float: left;
                margin-left: 15px;
            }
        }
    }

    .headerAccount{
        margin: 10px 0 10px 0;

        @media(min-width: 768px){
            border-bottom: none;
            padding-bottom: 0;
            display: inline;
           
        }

        .basketAccount {
            font-size: 14px;
            background: url(../images/account.png) 0 0 no-repeat;
            padding: 0 0 5px 28px;
            // display: inline-block;
            position: relative;

            @media(min-width: 768px) {
                float: left;
                margin-left: 15px;
                border-bottom: none;
            }
        }
    }
}


    .basketItems {
        font-size: 14px;
        display: inline-block;
        margin-left: 8px;
    }

    #basketPrice {
        .bItemsWrap {
            position: absolute;
            left: 15px;
            top: -5px;
        }

        .bItems {
            font-size: 12px;
            background: $pink_color;
            color: #fff;
            padding: 0 5px;
            vertical-align: top;
            border-radius: 100px;
            display: block;
        }
    }
}

.hlinks {
    float: left;
    width: 100%;
}

.checkoutLink {
    display: inline;
    color: #4173b9;
    font-size: 14px;
}

#checkoutLink {
    background: url(../images/new/checkoutbutton.png) top left no-repeat;
    display: inline-block;
    color: white;
    padding: 6px 38px 6px 8px;
    text-decoration: none;
    width: 97px;
    height: 29px;
}

#checkoutConditions {
	border: 4px solid $pink_color;
}

#helpLink {
    background: url(../images/new/helpbutton.png) top left no-repeat;
    display: inline-block;
    color: #fff;
    padding: 6px 26px 6px 10px;
    text-decoration: none;
    margin-right: 5px;
    width: 60px;
    height: 29px;
}

.headerInfo {
    background: #f9f9f9;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;

    .headerInfoWrap {
        float: left;
        width: 100%;
    }

    .info-item {
        padding: 15px 30px;
        font-size: 14px;
        text-align: center;

        span {
            font-weight: bold;
            color: $dark_text;
        }

        &.info-item-center {
            border-left: 1px solid #ccc;
            border-right: 1px solid #ccc;
        }
    }
}

#searchWrapper {
    padding: 14px 0;
    width: 100%;
    float: left;
    position: relative;

    @media(min-width: 992px) and (max-width: 1200px) {
    	padding: 18px 0;
    }

    form {
        margin: 0;
        float: none !important;
    }

    select {
        float: left;
        height: 30px;
        padding: 10px 5px;
        margin-right: 10px;
        width: 104px;
        font-size: 11px;
        border: 0;
        background: url(../images/headerselectbg.png) center no-repeat;
        font-family: Helvetica, arial, sans-serif;
        font-weight: 100;
        color: #4679c2;
        padding: 8px 5px;
    }

    option {
        background: #ffffff;
    }
}

#quickFindForm {
    background: #fff;
    border: 1px solid #ccc;

    @media(min-width: 768px) and (max-width: 992px) {
    	padding: 4px 15px;
    }

    #searchInput {
        border: none;
        font-size: 14px;

        &:focus {
            border-color: #cccccc;
            box-shadow: none;
        }
    }

    .searchButton {
        background: url(../images/search-btn.png) #fff no-repeat center;
        border-radius: 0;
        text-indent: -9000px;
        border-left: 0;
        padding: 0 12px;
    }
}

/*----------- NAVIGATION -----------*/

#navCatTabs {
    li {
        float: left;
        margin-right: 10px;

        a {
            font-family: 'Roboto';
            text-transform: uppercase;
            color: #fff;
            font-size: 12px;
            font-weight: $weight_light;
            padding: 10px;

            &:active, &:hover {
                color: #ca4f84;
            }
        }
    }

    .navCat {
        top: 40px;
        left: -20px;
        position: absolute;
        background: #fff;
        height: auto;
        list-style: none;
        padding: 20px 33px;
        width: 914px;
        z-index: 999;
        border-top: 5px solid #4c7fc6;
        display: none;
        box-shadow: 0px 0px 5px #989898;
        -webkit-shadow: 0px 0px 5px #989898;
        -moz-shadow: 0px 0px 5px #989898;
        -o-shadow: 0px 0px 5px #989898;
    }

    li:hover .navCat {
        display: block;
    }

    .navCat {
        li {
            float: left;
            list-style: none;
            width: 210px;
            display: inline;
            padding: 2px 0;
            padding-right: 8px;
            overflow: hidden;

            a {
                color: #fff;
                font-family: Helvetica, Arial, sans-serif;
                font-size: 11px;
                text-decoration: none;
                float: left;
                text-transform: none;

                &:hover {
                    color: #ca4f84;
                }
            }

            img {
                float: left;
                background: #333;
                width: 24px;
                height: 24px;
                border: 1px solid #ebebeb;
            }

            span {
                float: left;
                margin-left: 5px;
            }
        }

        .navCatTitle {
            font-size: 14px;
            text-decoration: none;
            text-transform: uppercase;
        }
    }
}

.sectionWrap {
    background: #fff;
    padding: 15px 15px 30px 15px;
}

.navbar {
    margin-bottom: 0;
}

/*----------- END NAVIGATION -----------*/

/*----------- SLIDER -----------*/

.homeSliderOuterWrapper {
    position: relative;

    .bannerContent {
        color: #fff;

        .title {
            color: #fff;
        }
    }
}

.rslides {
    position: relative;
    list-style: none;
    overflow: hidden;
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100% !important;

    li {
        -webkit-backface-visibility: hidden;
        position: absolute;
        display: none;
        width: 100%;
        left: 0;
        top: 0;
        min-height: 400px;
        background-size: cover;

        .container:before {
            background-color: #000;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;
            height: 100%;
            width: 100%;
        }

        &:first-child {
            position: relative;
            display: block;
            float: left;
        }

        .bannerContent {

        	.title {
				display: block;
				margin-top: 60px;
				margin-bottom: 30px;

				@media(min-width: 1200px) {
					margin-top: 90px;
				}
        	}

        	p {
        		margin-bottom: 40px;
        	}
        }
    }

    img {
        display: block;
        height: auto;
        float: left;
        width: 100%;
        border: 0;
        min-height: 450px;
    }
}

.rslides_nav {
    width: 13px;
    height: 21px;
    text-indent: -900em;
    display: inline-block;
    position: absolute;
    top: 50%;
    z-index: 999;

    &.prev {
        background: url(../images/arrow-prev.png) no-repeat;
        left: 20px;
    }

    &.next {
        background: url(../images/arrow-next.png) no-repeat;
        right: 20px;
    }

    &.prev:hover {
        background: url(../images/arrow-prev-h.png) no-repeat;
    }

    &.next:hover {
        background: url(../images/arrow-next-h.png) no-repeat;
    }
}

.centered-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 50%;
    left: 0;
    opacity: 0.7;
    text-indent: -9999px;
    overflow: hidden;
    text-decoration: none;
    height: 61px;
    width: 38px;
    background: transparent url("themes.gif") no-repeat left top;
    margin-top: -45px;
}

.centered-btns_nav:active {
    opacity: 1.0;
}

.centered-btns_nav.next {
    left: auto;
    background-position: right top;
    right: 0;
}

.transparent-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    top: 0;
    left: 0;
    display: block;
    background: #fff;

    /* Fix for IE6-9 */
    opacity: 0;
    filter: alpha(opacity = 1);
    width: 48%;
    text-indent: -9999px;
    overflow: hidden;
    height: 91%;
}

.transparent-btns_nav.next {
    left: auto;
    right: 0;
}

.large-btns_nav {
    z-index: 3;
    position: absolute;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    opacity: 0.6;
    text-indent: -9999px;
    overflow: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    background: #000 url("themes.gif") no-repeat left 50%;
    width: 38px;
}

.large-btns_nav:active {
    opacity: 1.0;
}

.large-btns_nav.next {
    left: auto;
    background-position: right 50%;
    right: 0;
}

.centered-btns_nav:focus,
.transparent-btns_nav:focus,
.large-btns_nav:focus {
    outline: none;
}

.centered-btns_tabs,
.transparent-btns_tabs,
.large-btns_tabs {
    margin-top: -40px;
    text-align: center;
}

.centered-btns_tabs li,
.transparent-btns_tabs li,
.large-btns_tabs li {
    display: inline;
    float: none;
    margin-right: 5px;
}

.centered-btns_tabs a,
.transparent-btns_tabs a,
.large-btns_tabs a {
    text-indent: -9999px;
    overflow: hidden;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    background: #ccc;
    background: rgba(0, 0, 0, 0.2);
    display: inline-block;
    _display: block;
    -webkit-box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.3);
    width: 12px;
    height: 12px;
}

.centered-btns_here a,
.transparent-btns_here a,
.large-btns_here a {
    background: #222;
    background: rgba(0, 0, 0, 0.8);
}

/*----------- END SLIDER -----------*/

/*----------- HOME PAGE -----------*/

#homeContent {
    .featuredCats {
        background: #ebebeb;

        h1 {
            text-align: center;
            font-weight: $weight_light;
        }

        h2 {
            text-align: center;
            color: $dark_text;
            font-weight: 700;
            margin-bottom: 40px;
        }

        .featCat {
            margin-bottom: 20px;

            img {
                width: 100%;
                height: auto;
            }

            .featCatTitle {
                display: block;
                width: 100%;
                padding: 10px;
                text-align: center;
                text-transform: uppercase;
                font-weight: $weight_medium;
                font-size: 18px;
                color: $dark_text;
            }

            .featCatImg {
                position: relative;
            }

            .featCatImg:after {
                content: 'Shop Now';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: url(../images/basket-white.png) no-repeat center 35% rgba(0, 0, 0, 0.8);
                opacity: 0;
                transition: all 0.5s;
                -webkit-transition: all 0.5s;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;
                padding-top: 35px;
                text-transform: uppercase;
            }

            .featCatImg:hover:after {
                opacity: 1;
            }
        }
    }

    .aboutUs {
		
		h2 {
			margin-top: 0;
			margin-bottom: 70px;
		}

        ul {
            list-style: none;

            li {
                margin-bottom: 15px;
                padding: 10px 0 10px 60px;
            }
        }

        .suppliers {
            background: url(../images/icon-makeup.png) no-repeat left center;
        }

        .delivery {
            background: url(../images/icon-delivery.png) no-repeat left center;
        }

        .experience {
            background: url(../images/icon-experience.png) no-repeat left center;
        }

        .tv {
            background: url(../images/icon-tv.png) no-repeat left center;
        }

        .sfx {
            background: url(../images/icon-tools.png) no-repeat left center;
        }

        .contacts {
            background: url(../images/icon-question.png) no-repeat left center;

            a {
                color: $pink_color;
                font-weight: bold;
            }
        }
    }

    .infoBoxes {
        background: #ebebeb;

        img {
            width: 100%;
            height: auto;
        }

        .infoItem {
            margin-bottom: 30px;
            box-shadow: 0px 0px 6px 1px #dddddd;
            -webkit-box-shadow: 0px 0px 6px 1px #dddddd;
            -moz-box-shadow: 0px 0px 6px 1px #dddddd;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }

            .content {
                background: #fff;
                padding: 20px 15px;
            }
        }

        .lastInfoItem {
        	margin-bottom: 0;
        }

        .title, .text, .button {
            display: block;
            text-align: center;
        }

        .text {
			@media(min-width: 992px) {
				min-height: 90px;
			}

			@media(min-width: 1270px) {
				min-height: auto;
			}
        }

        .title {
            font-weight: $weight_medium;
            font-size: 25px;
            color: $dark_text;

            @media(min-width: 992px) {
            	font-size: 30px;
            }
        }
    }

    .bottomBanner {
        align-items: center;
        background: url(../images/bottom-banner.jpg) no-repeat center center;
        background-size: cover;
        color: #fff;
        display: flex;
        justify-content: center;
        min-height: 475px;

        .button {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 20px 0;
        }

        .bannerContent {
            margin-top: 100px;
        }
    }

    .trustedBy {
    	position: relative;
		
		&:after {
			display: none;


			@media(min-width: 1200px) {
				content: '';
				display: block;
			    border: 1px solid #ebebeb;
			    position: absolute;
			    height: 155%;
			    right: 35px;
			    top: -35px;
			}
		}

        img {
        	margin-top: 30px;
        }
    }

    .trustedSocial .social {
        margin-top: 40px;

        @media (min-width: 1200px) {
            margin-top: 0;
        }

        .socialWrap {
            margin-top: 30px;
        }

        a {
            align-items: center;
            color: #fff;
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            padding: 10px 20px;
            text-decoration: none;
            width: 100%;

            @media (min-width: 992px) {
                margin-bottom: 0;
            }
        }

        .facebook {
            background: #3a559f;

            span {
                background: url(../images/icon-facebook.png) no-repeat;
                height: 26px;
                margin-right: 20px;
                width: 11px;
            }
        }

        .youtube {
            background: #dc472e;

            span {
                background: url(../images/icon-youtube.png) no-repeat;
                height: 28px;
                margin-right: 15px;
                width: 26px;
            }
        }

        .twitter {
            span {
                background: url(../images/icon-twitter.png) no-repeat;
                height: 26px;
                margin-right: 15px;
                width: 32px;
            }

            background: #50abf1;
        }

        .instagram {
            background: 
            // left bottom
            radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%),
            radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%),
            // left top
            radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%),
            radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%),
            //  right top
            radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%),
            radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%),
            // right bottom
            radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent),

            /////// create a base coat to smooth
            /////// corner gradients
            linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
            
            span {
                background: url(../images/icon-instagram.png) no-repeat;
                height: 26px;
                margin-right: 15px;
                width: 26px;
            }
        }
    }
}

/*----------- END HOME PAGE -----------*/

/*----------- FOOTER -----------*/

#footerSignup {
    background: #ebebeb;
    padding: 20px 0;

    .title {
        background: url(../images/icon-newsletter.png) no-repeat left;
        color: $dark_text;
        display: block;
        font-size: 22px;
        font-weight: $weight_regular;
        padding: 10px 0 10px 65px;
    }

    #newsletterEmail {
        color: #858585;
        float: left;
        height: 52px;
        padding-left: 20px;
		margin: 15px 0;

        @media (min-width: 1200px) {
            width: 59%;
            margin: 0;
        }
    }

    .subscribe-btn {
        width: 100%;

        @media (min-width: 1200px) {
            width: auto;
        }
    }
}

#footer {
    background: $dark_text;
    color: #fff;
    padding: 60px 0 10px 0;

    .footer-heading {
        margin-bottom: 10px;
        margin-top: 30px;
        display: block;
        font-size: 20px;
        font-weight: bold;
        text-transform: uppercase;

        @media (min-width: 992px) {
            margin-bottom: 30px;
        }
    }

    a {
        color: #fff;
    }

    .column-1 {
        span {
            display: block;
            font-size: 20px;
        }

        p {
            font-size: 14px;
            margin-top: 20px;
        }

        .logo {
            margin-bottom: 20px;
        }

        .title {
            font-weight: 700;
        }
    }

    ul {
        list-style: none;
        margin-left: 0;
    }

    .footerTop {
        font-size: 16px;

        ul li {
            margin: 10px 0;
        }

        .social {
            margin-top: 20px;
            margin-bottom: 0;

            li {
                display: inline-block;
                text-indent: -9999px;
                margin-right: 20px;

                a {
                    display: block;
                }

                &.facebook a {
                    background: url(../images/facebook-white.png) no-repeat left;
                    width: 11px;
                }

                &.youtube a {
                    background: url(../images/youtube-white.png) no-repeat left;
                    width: 22px;
                }

                &.twitter a {
                    background: url(../images/twitter-white.png) no-repeat left;
                    width: 22px;
                }

                &.instagram a {
                    background: url(../images/instagram-white.png) no-repeat left;
                    width: 22px;
                }
            }
        }
    }

    hr {
        border-color: #383838;
    }

    .footerBottom {
        font-size: 14px;
        color: #858585;

        a {
            font-size: 14px;
            color: #858585;
        }

        p {
            float: left;
            display: block;
        }

        ul {
            padding: 15px 0;
			float: left;
			clear: both;
			margin: 0;

            @media(min-width: 768px) {
            	float: right;
            	padding: 0;
            	clear: none;
            }

            li {
                display: inline;
                margin-right: 15px;
            }
        }
    }
}

.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 100px;
  right: 10px;
  z-index: 10;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  background: $pink_color url(../images/cd-top-arrow.svg) no-repeat center 50%;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
}
.cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
  -webkit-transition: opacity .3s 0s, visibility 0s 0s;
  -moz-transition: opacity .3s 0s, visibility 0s 0s;
  transition: opacity .3s 0s, visibility 0s 0s;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  opacity: .5;
}
.no-touch .cd-top:hover {
  background-color: #e86256;
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px;
  }
}

/*----------- END FOOTER -----------*/

/*---------- CATEGORY PAGE ----------*/

.categoryListBoxContents .productBoxContentsWrapper, .centerBoxContentsCrossSell .productBoxContentsWrapper {
    border: 1px solid #ebebeb;
    float: left;
    padding: 10px;
}

.categoryListBoxContents .productImgWrapper {
    border-bottom: 0;
    border: 1px solid #ebebeb;
    margin-bottom: 0;
}

.categoryListBoxContents .productImgWrapper img {
    padding: 5px;
}

.categoryListBoxContents a {
    float: left;
    overflow: hidden;
    text-decoration: none !important;
    width: 100%;
}

.categoryListBoxContentsCatName {
    background: #FAFAFA url(../images/catarrow.png) 96% 50% no-repeat;
    border: 1px solid #EBEBEB;
    color: #3A3A3C;
    float: left;
    font-size: 12px;
    padding: 10px 30px 10px 10px;
    width: 100%;
}

.medium .categoryListBoxContentsCatName {
    font-size: 14px;
}

.large .categoryListBoxContentsCatName {
    font-size: 16px;
}

#categoryDescription {
    font-size: 12px;
    margin-bottom: 25px;
}

.medium #categoryDescription {
    font-size: 14px;
    line-height: 19px;
}

.large #categoryDescription {
    font-size: 16px;
    line-height: 21px;
}

.productImgWrapper {
    display: block;
    float: left;
    height: 250px;
    line-height: 200px;
    margin-bottom: 10px;
    overflow: hidden;
    padding: 0;
    text-align: center;
    width: 100%;
}

.productImgWrapper img {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
}

.centerBoxContentsProducts .productBoxContentsWrapper {
    border: 1px solid #EBEBEB;
    padding: 10px;
    margin-bottom: 30px;
    float: left;
    background: #fff;
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.05);
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.05);
    -moz-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.05);
	//box-shadow: 0px 0px 6px 1px #dddddd;
	//-webkit-box-shadow: 0px 0px 6px 1px #dddddd;
	//-moz-box-shadow: 0px 0px 6px 1px #dddddd;
	padding: 30px;
	float: left;

	.showprice {
		color: $pink_color;
		font-weight: $weight_bold;
	}

	.smallVatPrice {
		font-weight: 400;
		margin-left: 10px;
		font-size: 14px;
	}

	.manufacturer {
		display: block;
		margin-bottom: 5px;
	}
}

.productBoxContentsWrapper {
	width: 100%;
	min-height: 475px;

	img {
		width: 100%;
		height: auto;
	}

	.featuredTitles {
	    color: #212121;
	    font-weight: 500;
	    margin-top: 20px;
	    margin-bottom: 10px;
	    display: inline-block;
	}

	.featuredContent {
		clear: both;
	}
}

.filterer {
	margin-bottom: 15px;
}

.last-filterer {
	margin-bottom: 0;
}

/*---------- END CATEGORY PAGE ----------*/

/*---------- PRODUCT PAGE ----------*/

#productinfoBody {
    #productGeneral {
        #productName {
            color: #212121;
            font-size: 50px;
            margin: 0;
        }

        .showprice {
            color: $pink_color;
            font-weight: bold;
            font-size: 40px;
        }

        .smallVatPrice {
        	margin-left: 10px;
        	font-weight: $weight_regular;
        }

        .manufacturer, .availability {
            span {
                color: #212121;
                font-weight: bold;
            }
        }

        #productDetailsList {
            list-style: none;
        }

        .prodImageWrap {
            align-items: center;
            background: #ebebeb;
            display: flex;
            justify-content: center;
            padding: 60px 30px;
            width: 100%;

            img {
            	width: 100%;
            	height: auto;
            }
        }

        #productAdditionalImages {
			margin-top: 10px;
				
			.row {
				margin-right: -5px;
				margin-left: -5px;

				.col-xs-3 {
					padding-right: 5px;
					padding-left: 5px;
				}
			}

        	img {
        		width: 100%;
        		height: auto;
        	}
        }

        .button_in_cart {
            background-image: none;
            background: url(../images/add-to-basket.png) no-repeat 55px #75b743;
            border-radius: 0;
            box-shadow: none;
            text-shadow: none;
            text-transform: uppercase;
            border: none;
            font-weight: $weight_light;
            padding: 15px 15px 15px 35px;
            margin-top: 25px;
			margin-left: 10px;
			height: 50px;
			min-width: 280px;
	
			@media(min-width: 768px) {
				background: url(../images/add-to-basket.png) no-repeat 100px #75b743;
				margin-top: 25px;
				margin-left: 10px;
				min-width: 355px;
			}

        }

        #cartAdd {
        	margin-bottom: 20px;

        	.quaWrap {
        		float: left;

        		#mainQuantity {
        			max-width: 115px;
        			min-height: 50px;
        		}	
        	}
        }
    }

    .prodInfoBottom {
        .title {
            color: #212121;
            font-weight: bold;
            margin-bottom: 15px;
            display: block;
        }

        .imp-deliveryInfo, .deliveryInfo {
			padding: 0px 15px 15px 70px;
        }

        .imp-deliveryInfo {
            background: url(../images/icon-hazard.png) no-repeat;
            margin-bottom: 40px;
        }

        .deliveryInfo {
            background: url(../images/icon-prod-delivery.png) no-repeat;

            @media(min-width: 992px) {
            	border-right: 1px solid #eeeeee;
        	}
        }

        /* ------ SPEC TABLE ------*/
        .specTable {
            table {
            	background: #f9f9f9;
            }
        }

        .datasheet {
        	display: inline-flex;
        	align-items: center;
        	justify-content: center;
        	width: 100%;

        	@media(min-width: 992px) {
            	width: auto;
        	}

        	.icon {
				background: url(../images/icon-download.png) no-repeat;
				width: 22px;
				height: 20px;
				margin-right:10px;
			}

        }
    }

    /*---------- XSELL PRODUCT PAGE ----------*/
	
	.crossSellWrap {
		background: #ebebeb;

		h2 {
			margin: 0 0 70px 0;
		}

		.related-carousel-wrap {
			.owl-stage-outer {
				overflow: hidden;

				.owl-stage {
					.owl-item {
						background: #fff;
						box-shadow: 0px 0px 6px 1px #dddddd;
		            	-webkit-box-shadow: 0px 0px 6px 1px #dddddd;
		            	-moz-box-shadow: 0px 0px 6px 1px #dddddd;
						padding: 30px;
						float: left;

						.title {
							color: $dark_text;
							font-weight: $weight_medium;
							margin-top: 20px;
							margin-bottom: 10px;
							display: block;
						}

						.manufacturer {
							display: block;
							margin-bottom: 25px;
						}

						.showprice {
							color: $pink_color;
							font-weight: $weight_bold;
						}

						.smallVatPrice {
							margin-left: 10px;
							font-weight: $weight_regular;
							font-size: 16px;
						}

						img {
							width: 100%;
							height: auto;
						}
					}
				}
			}

			.owl-nav {
				&.disabled {
					display: none;
				}
			}

			.owl-dots {
				margin-top: 70px;

				.owl-dot  {

					span {
						background: #df94a7;
						width: 15px;
						height: 15px;
					}	

					&.active span {
						background: $pink_color;
					}

				}
			}
		}
	}

    /*---------- END XSELL PRODUCT PAGE ----------*/

    .prodBottomBanner {
    	background: $pink_color;
    	padding: 60px 0;

		span.h2, span.h5 {
			color: #fff;
		}

		span.subtitle {
			font-weight: $weight_light;
			margin-top: 20px;

			a {
				color: #fff;
				font-weight: bold;
			}
		}
    }
}

/*---------- END PRODUCT PAGE ----------*/

#contactUsDefault {
	
	.button_send {
		margin-top: 10px;
		width: 100%;

		@media(min-width: 768px) {
			float: right;
			width: auto;
			min-width: 230px;
		}
	}

	#contactInfo {
		dl {
			margin-bottom: 0;

			dt {
				color: $pink_color;
				margin-top: 30px;
			}
		}

		address {
			font-weight: 300;
		}

		.social {
			list-style: none;

			margin-top: 10px;
            margin-bottom: 0;

            li {
                display: inline-block;
                text-indent: -9999px;
                margin-right: 20px;

                a {
                    display: block;
                }

                &.facebook a {
                    background: url(../images/facebook-dark.png) no-repeat left;
                    width: 11px;
                }

                &.youtube a {
                    background: url(../images/youtube-dark.png) no-repeat left;
                    width: 22px;
                }

                &.twitter a {
                    background: url(../images/twitter-dark.png) no-repeat left;
                    width: 22px;
                }
            }
		}
	}
}

/*---------- DEFAULT PAGES ----------*/

.centerColumn {
	h1 {
		font-size: 22px;
		clear: both;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 17px;
	}
}

#createAcctSuccessMainContent {
	margin-bottom: 30px;
}

/*---------- END DEFAULT PAGES ----------*/


/*---------- LOGIN / CREATE ACCOUNT ----------*/

.loginWrap {

	#signupDefault {
		clear: both;
		display: none;
	}

	#loginDefault, #newCust {
	    margin: 20px 0;
	    background: #fff;
	    padding-bottom: 30px;
	    clear: both;

	    h3 {
		clear: both;
		padding: 25px 10px 25px 40px;
		margin-bottom: 0;
		border: 1px solid #ccc;
		border-bottom: 0;

			&#loginDefaultHeading {
				background: #ebebeb url(../images/login.png)no-repeat 13px 24px;
			}

			&#signupDefaultHeading {
				background: #ebebeb url(../images/login.png)no-repeat 13px 24px;
			}
		}
	}

	.inner {
		border: 1px solid #ccc;
		padding: 15px;
		min-height: 250px;

		.loginContentWrap {
			margin-top: 40px;
		}

		p {
			margin-bottom: 15px;
		}
    }

    .button_login {
        @media(min-width: 1200px) {
            float: right;
        }
    }
    
    .btn {
        width: 100%;

        @media(min-width: 1200px) {
            width: auto;
        }
    }
}
/*---------- END LOGIN / CREATE ACCOUNT ----------*/


/*---------- CHECKOUT ----------*/

#checkoutBody {

	#cartContentsDisplay {

		#ccTotalHeading {
			text-align: right;
		}

		tbody {
			color: $dark_text;

			.cartImage {
				margin-right: 10px;
			}

			.cartProdName {
				color: $dark_text;
				text-transform: uppercase;
				font-weight: $weight_bold;
			}

			.price, .finalprice {
				font-weight: $weight_bold;
			}
		}
	}

	#orderTotals {
		font-weight: $weight_bold;
		color: $dark_text;
	}

	#disc-ot_coupon {
		margin-top: 15px;
		margin-bottom: 15px;
	}

	.btn {
		width: 100%;
    }
    
    .subscribe-btn {
        width: auto !important;
    }

	.address-button {
		margin-bottom: 15px;

		@media(min-width: 1200px) {
			float: right;	
		}
	}
}

.pseudolink {
    text-decoration: underline;
}


/*---------- END CHECKOUT ----------*/

.openDropdown > .dropdown-menu {
    display: block;
}

.topMenuNav {
    margin-left: 0px;
    list-style-type: none;
}

.topMenuNav > li {
    padding: 10px;
}

.six {
    width: 500px;
}

/*---------- DROPZONE ----------*/
@-webkit-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }
@-moz-keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }
@keyframes passing-through {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30%, 70% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-40px);
    -moz-transform: translateY(-40px);
    -ms-transform: translateY(-40px);
    -o-transform: translateY(-40px);
    transform: translateY(-40px); } }
@-webkit-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@-moz-keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@keyframes slide-in {
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    transform: translateY(40px); }
  30% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@-moz-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  10% {
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1); }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
.idRequired{
    p{
        font-size: 17px;
    }
}
.dropzone, .dropzone * {
  box-sizing: border-box; }

.dropzone {
  min-height: 150px;
  border: 2px solid rgba(0, 0, 0, 0.3);
  background: white;
  padding: 20px 20px; }
  .dropzone.dz-clickable {
    cursor: pointer; }
    .dropzone.dz-clickable * {
      cursor: default; }
    .dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
      cursor: pointer; }
  .dropzone.dz-started .dz-message {
    display: none; }
  .dropzone.dz-drag-hover {
    border-style: solid; }
    .dropzone.dz-drag-hover .dz-message {
      opacity: 0.5; }
  .dropzone .dz-message {
    text-align: center;
    margin: 2em 0; }
  .dropzone .dz-preview {
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 16px;
    min-height: 100px; }
    .dropzone .dz-preview:hover {
      z-index: 1000; }
      .dropzone .dz-preview:hover .dz-details {
        opacity: 1; }
    .dropzone .dz-preview.dz-file-preview .dz-image {
      border-radius: 20px;
      background: #999;
      background: linear-gradient(to bottom, #eee, #ddd); }
    .dropzone .dz-preview.dz-file-preview .dz-details {
      opacity: 1; }
    .dropzone .dz-preview.dz-image-preview {
      background: white; }
      .dropzone .dz-preview.dz-image-preview .dz-details {
        -webkit-transition: opacity 0.2s linear;
        -moz-transition: opacity 0.2s linear;
        -ms-transition: opacity 0.2s linear;
        -o-transition: opacity 0.2s linear;
        transition: opacity 0.2s linear; }
    .dropzone .dz-preview .dz-remove {
      font-size: 14px;
      text-align: center;
      display: block;
      cursor: pointer;
      border: none; }
      .dropzone .dz-preview .dz-remove:hover {
        text-decoration: underline; }
    .dropzone .dz-preview:hover .dz-details {
      opacity: 1; }
    .dropzone .dz-preview .dz-details {
      z-index: 20;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      font-size: 13px;
      min-width: 100%;
      max-width: 100%;
      padding: 2em 1em;
      text-align: center;
      color: rgba(0, 0, 0, 0.9);
      line-height: 150%; }
      .dropzone .dz-preview .dz-details .dz-size {
        margin-bottom: 1em;
        font-size: 16px; }
      .dropzone .dz-preview .dz-details .dz-filename {
        white-space: nowrap; }
        .dropzone .dz-preview .dz-details .dz-filename:hover span {
          border: 1px solid rgba(200, 200, 200, 0.8);
          background-color: rgba(255, 255, 255, 0.8); }
        .dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
          overflow: hidden;
          text-overflow: ellipsis; }
          .dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
            border: 1px solid transparent; }
      .dropzone .dz-preview .dz-details .dz-filename span, .dropzone .dz-preview .dz-details .dz-size span {
        background-color: rgba(255, 255, 255, 0.4);
        padding: 0 0.4em;
        border-radius: 3px; }
    .dropzone .dz-preview:hover .dz-image img {
      -webkit-transform: scale(1.05, 1.05);
      -moz-transform: scale(1.05, 1.05);
      -ms-transform: scale(1.05, 1.05);
      -o-transform: scale(1.05, 1.05);
      transform: scale(1.05, 1.05);
      -webkit-filter: blur(8px);
      filter: blur(8px); }
    .dropzone .dz-preview .dz-image {
      border-radius: 20px;
      overflow: hidden;
      width: 200px;
      height: 200px;
      position: relative;
      display: block;
      z-index: 10; }
      .dropzone .dz-preview .dz-image img {
        display: block; }
    .dropzone .dz-preview.dz-success .dz-success-mark {
      -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview.dz-error .dz-error-mark {
      opacity: 1;
      -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
      animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1); }
    .dropzone .dz-preview .dz-success-mark, .dropzone .dz-preview .dz-error-mark {
      pointer-events: none;
      opacity: 0;
      z-index: 500;
      position: absolute;
      display: block;
      top: 50%;
      left: 50%;
      margin-left: -27px;
      margin-top: -27px; }
      .dropzone .dz-preview .dz-success-mark svg, .dropzone .dz-preview .dz-error-mark svg {
        display: block;
        width: 54px;
        height: 54px; }
    .dropzone .dz-preview.dz-processing .dz-progress {
      opacity: 1;
      -webkit-transition: all 0.2s linear;
      -moz-transition: all 0.2s linear;
      -ms-transition: all 0.2s linear;
      -o-transition: all 0.2s linear;
      transition: all 0.2s linear; }
    .dropzone .dz-preview.dz-complete .dz-progress {
      opacity: 0;
      -webkit-transition: opacity 0.4s ease-in;
      -moz-transition: opacity 0.4s ease-in;
      -ms-transition: opacity 0.4s ease-in;
      -o-transition: opacity 0.4s ease-in;
      transition: opacity 0.4s ease-in; }
    .dropzone .dz-preview:not(.dz-processing) .dz-progress {
      -webkit-animation: pulse 6s ease infinite;
      -moz-animation: pulse 6s ease infinite;
      -ms-animation: pulse 6s ease infinite;
      -o-animation: pulse 6s ease infinite;
      animation: pulse 6s ease infinite; }
    .dropzone .dz-preview .dz-progress {
      opacity: 1;
      z-index: 1000;
      pointer-events: none;
      position: absolute;
      height: 16px;
      left: 50%;
      top: 50%;
      margin-top: -8px;
      width: 80px;
      margin-left: -40px;
      background: rgba(255, 255, 255, 0.9);
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden; }
      .dropzone .dz-preview .dz-progress .dz-upload {
        background: #333;
        background: linear-gradient(to bottom, #666, #444);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        -webkit-transition: width 300ms ease-in-out;
        -moz-transition: width 300ms ease-in-out;
        -ms-transition: width 300ms ease-in-out;
        -o-transition: width 300ms ease-in-out;
        transition: width 300ms ease-in-out; }
    .dropzone .dz-preview.dz-error .dz-error-message {
      display: block; }
    .dropzone .dz-preview.dz-error:hover .dz-error-message {
      opacity: 1;
      pointer-events: auto; }
    .dropzone .dz-preview .dz-error-message {
      pointer-events: none;
      z-index: 1000;
      position: absolute;
      display: block;
      display: none;
      opacity: 0;
      -webkit-transition: opacity 0.3s ease;
      -moz-transition: opacity 0.3s ease;
      -ms-transition: opacity 0.3s ease;
      -o-transition: opacity 0.3s ease;
      transition: opacity 0.3s ease;
      border-radius: 8px;
      font-size: 13px;
      top: 130px;
      left: -10px;
      width: 140px;
      background: #be2626;
      background: linear-gradient(to bottom, #be2626, #a92222);
      padding: 0.5em 1.2em;
      color: white; }
      .dropzone .dz-preview .dz-error-message:after {
        content: '';
        position: absolute;
        top: -6px;
        left: 64px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 6px solid #be2626; }
